import React, { useState, useEffect, useRef } from 'react'
import styles from './Header.module.sass'
import LanguageSelector from '../LanguageSelector'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { changeTheme } from '../../store/slices/userSlice'
import WaveDown from '../../components/WaveDown'

const navItems = ['Курсы', 'Статьи', 'Обьявления', 'О нас']

function Header ({ changeTheme, data }) {
  const { theme } = data
  const [menu, setMenu] = useState(false)
  const menuRef = useRef(null)

  const handleThemeChange = newTheme => {
    if (theme !== newTheme) {
      changeTheme(newTheme)
    }
  }

  const handleOutsideClick = e => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !e.target.closest(`.${styles.menuBtn}`)
    ) {
      setMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1100) {
        setMenu(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <header className={styles.header}>
      <WaveDown />
      <div className={styles.navigation}>
        <div className={styles.logo}>
          <div className={styles.imgBox}>
            <img src='/images/LearnHub_logo.png' alt='LearnHub_logo' />
          </div>
        </div>
        <div className={styles.navigationBtn}>
          <div className={`${styles.activeBtn} ${styles.hoverBtn}`}>
            Главная
          </div>
          {navItems.map((item, index) => (
            <div key={index} className={styles.hoverBtn}>
              {item}
            </div>
          ))}
        </div>
        <div className={styles.menuBox}>
          <div className={styles.userBtn}>
            <div className={styles.imgBox}>
              <img
                className='userImg'
                src='/images/User_fill.png'
                alt='User_fill.png'
              />
            </div>
            <span>Войти</span>
          </div>
          <div
            className={styles.menuBtn}
            onClick={() => {
              setMenu(!menu)
            }}
          >
            <img
              className={styles.menuIcon}
              src='/images/IconMenu.png'
              alt='IconMenu.png'
            />
          </div>
        </div>
        {menu && (
          <div className={styles.openMenu} ref={menuRef}>
            <div className={styles.navigationBox}>
              <p className={styles.title}>Навигация</p>
              <div className={styles.line}></div>
              <div className={styles.box}>
                <div className={`${styles.activeBtn} ${styles.hoverBtn}`}>
                  Главная
                </div>
                {navItems.map((item, index) => (
                  <div key={index} className={styles.hoverBtn}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.settingsBox}>
              <p className={styles.title}>Настройки</p>
              <div className={styles.line}></div>
              <div className={styles.flexBox}>
                <div
                  onClick={() =>
                    handleThemeChange(theme === 'light' ? 'dark' : 'light')
                  }
                  className={styles.themeBox}
                >
                  <span className={styles.themeText}>Тема</span>
                  {theme === 'light' ? (
                    <FontAwesomeIcon className={styles.icon} icon={faSun} />
                  ) : (
                    <FontAwesomeIcon className={styles.icon} icon={faMoon} />
                  )}
                </div>
                <div className={styles.languageBox}>
                  <span className={styles.languageText}>Язык</span>
                  <LanguageSelector />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

const mapStateToProps = state => state.userStore
const mapDispatchToProps = dispatch => ({
  changeTheme: theme => dispatch(changeTheme({ theme: theme }))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
