import React from 'react'
import styles from './WhatYouCanFind.module.sass'
import listCategory from '../../store/DataJSON/listCategory.json'

function WhatYouCanFind () {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h4 className={styles.title}>Что вы можете у нас найти</h4>
        <div className={styles.listCategory}>
          {listCategory.map((category, index) => (
            <div
              key={`${category.name} ${index}`}
              className={styles.itemContainer}
            >
              <img
                src={`/images/${category.categoryImg}`}
                alt={category.categoryImg}
                className={styles.itemImg}
              />
              <div className={styles.itemContent}>
                <p className={styles.itemTitle}>{category.categoryName}</p>
                <div className={styles.itemShortDesc}>
                  <div className={styles.twoBoxDesc}>
                    <div className={styles.oneBoxDesc}>
                      <img
                        src='/images/Time_fill.png'
                        alt='itemDescImg'
                        className={styles.itemDescImg}
                      ></img>
                      <span className={styles.itemDescText}>
                        {category.categoryHours}
                      </span>
                    </div>
                    <div className={styles.oneBoxDesc}>
                      <img
                        src='/images/User_fill_Banner.png'
                        alt='itemDescImg'
                        className={styles.itemDescImg}
                      ></img>
                      <span className={styles.itemDescText}>
                        {category.categoryStudents}
                      </span>
                    </div>
                  </div>
                  <div className={styles.twoBoxDesc}>
                    <div className={styles.oneBoxDesc}>
                      <img
                        src='/images/Subtract.png'
                        alt='itemDescImg'
                        className={styles.itemDescImg}
                      ></img>
                      <span className={styles.itemDescText}>
                        {category.categoryCourses}
                      </span>
                    </div>
                    <div className={styles.oneBoxDesc}>
                      <img
                        src='/images/Book_check_fill.png'
                        alt='itemDescImg'
                        className={styles.itemDescImg}
                      ></img>
                      <span className={styles.itemDescText}>
                        {category.categoryArticles}
                      </span>
                    </div>
                  </div>
                </div>
                <p className={styles.itemDesc}>{category.categoryText}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.flexBox}>
          <div className={styles.seeMoreBtn}>
            <span className={styles.btnText}>Посмотреть больше</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatYouCanFind
