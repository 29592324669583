import React, { useState } from 'react'
import styles from './Q&A.module.sass'
import QA from '../../store/DataJSON/QA.json'

function QandA () {
  const [activeIndex, setActiveIndex] = useState(null)

  const toggleAnswer = index => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h6 className={styles.title}>Часто задаваемые вопросы</h6>
        <div className={styles.QandAContainer}>
          {QA.map((item, index) => (
            <div className={styles.QandABox} key={`${item.question} ${index}`}>
              <div
                className={styles.questionBox}
                onClick={() => toggleAnswer(index)}
              >
                <p className={styles.question}>{item.question}</p>
                <div className={styles.imgBox}>
                  <img
                    className={`${styles.img} ${
                      activeIndex === index ? styles.rotate : ''
                    }`}
                    src='/images/Arrow.png'
                    alt='arrow'
                  />
                </div>
              </div>
              {activeIndex === index && (
                <p className={styles.answer}>{item.answer}</p>
              )}
              <div className={styles.line}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default QandA
