import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Banner from '../../components/Banner'
import ScrollBanner from '../../components/ScrollBanner'
import WhatYouCanFind from '../../components/WhatYouCanFind'
import ReviewList from '../../components/ReviewsList'
import QandA from '../../components/Q&A'
import WhatWeAchieved from '../../components/WhatWeAchieved'
import ComeToUs from '../../components/ComeToUs'

function HomePage () {
  useEffect(() => {
    AOS.init({ duration: 1000 })
  })

  return (
    <>
      <Banner />
      <ScrollBanner />
      <WhatYouCanFind />
      <ReviewList />
      <WhatWeAchieved />
      <ComeToUs />
      <QandA />
    </>
  )
}

export default HomePage
