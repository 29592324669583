import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import StarGenerator from '../StarGenerator'
import styles from './ReviewList.module.sass'
import './ReviewsList.sass'
import reviewsContent from '../../store/DataJSON/reviewsContent.json'

const settings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000
}

function ReviewList () {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={`${styles.sliderContent} rewievContent`}>
          <Slider {...settings}>
            {reviewsContent.map((slide, index) => (
              <div key={index} className={styles.sliderItem}>
                <div className={styles.flexRow}>
                  <p className={styles.userName}>{slide.userName}</p>
                  <div className={styles.dateBox}>
                    <img
                      className={styles.dateImg}
                      src='/images/date.png'
                      alt='date.png'
                    />
                    <p className={styles.date}>{slide.date}</p>
                  </div>
                </div>
                <div className={styles.flexRow}>
                  <div className={styles.starsBox}>
                    {StarGenerator(slide.rating)}
                  </div>
                  <p className={styles.category}>{slide.category}</p>
                </div>
                <p className={styles.text}>{slide.text}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default ReviewList
