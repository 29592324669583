import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import styles from './LanguageSelector.module.sass'
import { changeLanguage } from '../../store/slices/userSlice'

const LanguageSelector = ({ data, changeLanguage }) => {
  const { language } = data
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(language)

  const selectRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSelect = value => {
    if (selectedLanguage !== value) {
      setSelectedLanguage(value)
      changeLanguage(value)
    }
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles.customSelect} ref={selectRef}>
      <div className={styles.selected} onClick={toggleDropdown}>
        {selectedLanguage.toUpperCase()}
      </div>
      {isOpen && (
        <ul className={styles.optionsList}>
          <li className={styles.option} onClick={() => handleSelect('ru')}>
            <span>RU</span>
          </li>
          <li className={styles.option} onClick={() => handleSelect('en')}>
            <span>EN</span>
          </li>
          <li className={styles.option} onClick={() => handleSelect('ua')}>
            <span>UA</span>
          </li>
        </ul>
      )}
    </div>
  )
}

const mapStateToProps = state => state.userStore
const mapDispatchToProps = dispatch => ({
  changeLanguage: language => dispatch(changeLanguage({ language: language }))
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
