import React from 'react'
import styles from './ComeToUs.module.sass'

function ComeToUs () {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.text}>
          <span className={styles.blueText}>Присоединяйтесь</span> к нашей
          платформе и получите доступ к{' '}
          <span className={styles.blueText}>более чем 1400 курсам</span> в
          различных отраслях.{' '}
          <span className={styles.blueText}>Более 23 тысяч отзывов</span>{' '}
          подтверждают высокое качество нашего обучения!
        </p>
        <div className={styles.btn}>
          <span className={styles.btnText}>Присоединиться к нам</span>
        </div>
      </div>
    </div>
  )
}

export default ComeToUs
