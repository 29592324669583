import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styles from './Banner.module.sass'
import words from '../../store/DataJSON/bannerWords.json'

function Banner ({ data }) {
  const [text, setText] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [loopNum, setLoopNum] = useState(0)
  const [charIndex, setCharIndex] = useState(0)
  const typingSpeed = isDeleting ? 100 : 150
  const pauseBetweenWords = 1000
  const [aosEffect, setAosEffect] = useState('fade-right')

  useEffect(() => {
    const handleResize = () => {
      const effect = window.innerWidth <= 500 ? 'fade-up' : 'fade-right'
      setAosEffect(effect)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const handleTyping = () => {
      const currentWord = words[loopNum % words.length]
      const isFullWordTyped = charIndex === currentWord.length
      const isWordFullyDeleted = charIndex === 0

      if (isFullWordTyped && !isDeleting) {
        setTimeout(() => setIsDeleting(true), pauseBetweenWords)
      } else if (isWordFullyDeleted && isDeleting) {
        setIsDeleting(false)
        setLoopNum(loopNum + 1)
      } else {
        const nextCharIndex = isDeleting ? charIndex - 1 : charIndex + 1
        setCharIndex(nextCharIndex)
        setText(currentWord.substring(0, nextCharIndex))
      }
    }

    const typingTimeout = setTimeout(handleTyping, typingSpeed)

    return () => clearTimeout(typingTimeout)
  }, [charIndex, isDeleting, loopNum, typingSpeed])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          key={aosEffect}
          className={styles.textOverlay}
          data-aos={aosEffect}
          data-aos-delay='200'
        >
          <h2 className={styles.textTitle}>
            <span className={styles.blueSpan}>Учитесь каждый день</span>, чтобы
            строить будущее, о котором мечтаешь.
          </h2>
          <p className={styles.textUnderTitle}>
            <span className={styles.textPart}>Попробуйте себя в</span>
            <span className={styles.textPartWithBlueSpan}>
              <span className={styles.blueSpan}>{text}</span>|
            </span>
          </p>
          <div className={styles.bannerBtns}>
            <div className={styles.bannerBtnFirst}>
              <p className={styles.bannerBtnText}>Изучайте вместе с нами</p>
            </div>
            <div className={styles.bannerBtnSecond}>
              <p className={styles.bannerBtnText}>
                Создавай, вдохновляй и учи мир с нами.
              </p>
            </div>
          </div>
          <div className={styles.bannerDesc}>
            <div className={styles.descItem}>
              <div className={styles.imgBox}>
                <img
                  className={styles.descImg}
                  src='/images/User_fill_Banner.png'
                  alt='User_fill_Banner.png'
                />
                <p className={styles.descText}>200k+</p>
              </div>
              <p className={styles.descText}>студентов</p>
            </div>
            <div className={styles.descItem}>
              <div className={styles.imgBox}>
                <img
                  className={styles.descImg}
                  src='/images/Subtract.png'
                  alt='Subtract.png'
                />
                <p className={styles.descText}>500</p>
              </div>
              <p className={styles.descText}>курсов</p>
            </div>
            <div className={styles.descItem}>
              <div className={styles.imgBox}>
                <img
                  className={styles.descImg}
                  src='/images/Book_check_fill.png'
                  alt='Book_check_fill.png'
                />
                <p className={styles.descText}>1200</p>
              </div>
              <p className={styles.descText}>статей</p>
            </div>
          </div>
        </div>
        <img
          data-aos='fade-left'
          data-aos-delay='500'
          className={styles.img}
          src={`/images/EduOnline-${
            data.theme === 'dark' ? 'white' : 'blue'
          }.png`}
          alt='EduOnline-white'
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => state.userStore

export default connect(mapStateToProps, null)(Banner)
