import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styles from './Footer.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { changeTheme } from '../../store/slices/userSlice'
import LanguageSelector from '../LanguageSelector'
import Wave from '../../components/Wave'
import themes from '../../store/DataJSON/Themes.json'

const navItems = ['Курсы', 'Статьи', 'Обьявления', 'О нас']

const socialItems = [
  { name: 'Youtube', img: 'youtube.png' },
  { name: 'Instagram', img: 'instagram.png' },
  { name: 'Telegram', img: 'telegram.png' },
  { name: 'Facebook', img: 'facebook.png' },
  { name: 'Linkedin', img: 'linkedin.png' }
]

const politicaItems = [
  'Политика пользователя',
  'Политика конфиденциальности',
  'Политика куки'
]

function Footer ({ changeTheme, data }) {
  const { theme } = data

  const handleThemeChange = newTheme => {
    if (theme !== newTheme) {
      changeTheme(newTheme)
    }
  }

  useEffect(() => {
    const updateThemeVariables = () => {
      const themeVariables = themes[theme]
      for (const [key, value] of Object.entries(themeVariables)) {
        document.documentElement.style.setProperty(key, value)
      }
    }

    updateThemeVariables()
  }, [theme])

  return (
    <footer className={styles.container}>
      <Wave />
      <div className={styles.contentBox}>
        <div className={styles.content}>
          <div className={styles.logoBox}>
            <img src='/images/LearnHub_logo.png' alt='LearnHub_logo' />
          </div>
          <div className={styles.footeContent}>
            <div className={styles.navigationBox}>
              <p className={styles.title}>Навигация</p>
              <div className={styles.line}></div>
              <div className={styles.box}>
                <div className={`${styles.activeBtn} ${styles.hoverBtn}`}>
                  Главная
                </div>
                {navItems.map((item, index) => (
                  <div key={index} className={styles.hoverBtn}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.socialBox}>
              <p className={styles.title}>Контакты</p>
              <div className={styles.line}></div>
              <div className={styles.box}>
                {socialItems.map((item, index) => (
                  <div key={index} className={styles.hoverBtn}>
                    <img src={`/images/${item.img}`} alt={item.name} />
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.socialBox}>
              <p className={styles.title}>Политики</p>
              <div className={styles.line}></div>
              <div className={styles.box}>
                {politicaItems.map((item, index) => (
                  <div key={index} className={styles.hoverBtn}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.settingsBox}>
              <p className={styles.title}>Настройки</p>
              <div className={styles.line}></div>
              <div className={styles.flexBox}>
                <div
                  onClick={() =>
                    handleThemeChange(theme === 'light' ? 'dark' : 'light')
                  }
                  className={styles.themeBox}
                >
                  <span className={styles.themeText}>Тема</span>
                  {theme === 'light' ? (
                    <FontAwesomeIcon className={styles.icon} icon={faSun} />
                  ) : (
                    <FontAwesomeIcon className={styles.icon} icon={faMoon} />
                  )}
                </div>
                <div className={styles.languageBox}>
                  <span className={styles.languageText}>Язык</span>
                  <LanguageSelector />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.disclaimer}>
          © 2024. LearnHub. All Rights Reserved. Prices excl. VAT.
        </div>
      </div>
    </footer>
  )
}

const mapStateToProps = state => state.userStore
const mapDispatchToProps = dispatch => ({
  changeTheme: theme => dispatch(changeTheme({ theme: theme }))
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
