import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './ScrollBanner.module.sass'
import './ScrollBanner.sass'
import slideContent from '../../store/DataJSON/slideContent.json'

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

function ScrollBanner () {
  const [aosEffect1, setAosEffect1] = useState('')
  const [aosEffect2, setAosEffect2] = useState('')
  const [aosDelay, setAosDelay] = useState('')

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setAosEffect1('fade-right')
        setAosEffect2('fade-left')
        setAosDelay('400')
      } else {
        setAosEffect1('')
        setAosEffect2('')
        setAosDelay('')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          key={`aosEffect2${aosEffect2}`}
          data-aos={aosEffect2}
          data-aos-delay={aosDelay}
          className={styles.textContent}
        >
          <h3 className={styles.title}>Наши возможности</h3>
          <p className={styles.underTitleText}>
            Наши программы помогают студентам быстро прогрессировать, а авторам
            создавать вдохновляющий контент, используя эффективные инструменты
            для обучения и творчества.
          </p>
          <p className={styles.underTitleText}>
            Во всём, что касается продвижения, финансов и безопасности —
            доверьтесь нам.
          </p>
        </div>
        <div
          key={`aosEffect1${aosEffect1}`}
          data-aos={aosEffect1}
          data-aos-delay={aosDelay}
          className={`${styles.sliderContent} sliderContent`}
        >
          <Slider {...settings}>
            {slideContent.map((slide, index) => (
              <div key={index} className={styles.sliderItem}>
                <div className={styles.sliderItemContent}>
                  <div className={styles.sliderTitle}>
                    <div className={styles.imgDiv}>
                      <img src={`/images/${slide.img}`} alt='img' />
                    </div>
                    <p className={styles.sliderName}>{slide.name}</p>
                  </div>
                  <p className={styles.sliderText}>{slide.text}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default ScrollBanner
